import Image from 'next/image'
import Link from 'next/link'

import { Carousel } from '@/components/styleguide/Carousel'
import styles from './brands.module.scss'
import { constants, FILTER_VEHICLE_TYPES_CARS, FILTER_VEHICLE_TYPES_VANS } from '@/lib/constants'
import { FILTERS } from '@/lib/filters'
import { getUrlReadyString } from '@/lib/utilities/vehicle'

export type BrandsProps = {
  isVans: boolean
}

export const Brands = ({ isVans }: BrandsProps) => {
  return (
    <Carousel
      header={<h2>Brands A-Z</h2>}
      scrollValue={493}
      moreLink={isVans ? 'van-leasing/search' : 'car-leasing/search'}
      moreLabel="brands"
      sectionName="brands">
      {FILTERS.manufacturers
        .filter(m => {
          const type = isVans ? FILTER_VEHICLE_TYPES_VANS : FILTER_VEHICLE_TYPES_CARS
          return m.vehicleTypes.includes(type)
        })
        .map(item => {
          return (
            <Link
              href={`/${isVans ? 'van' : 'car'}-leasing/${getUrlReadyString(item.name)}`}
              key={item.name}>
              <a
                className={styles.link}
                data-name={item.name.toLowerCase()}
                data-needs-white={item.name !== 'Ford'}>
                <div className={styles.image}>
                  <Image
                    src={`${
                      constants.imageHost.assets
                    }/manufacturer-logos/symbol-only/${getUrlReadyString(item.name)}.png`}
                    alt={item.name}
                    height={56}
                    width={56}
                  />
                </div>
                <p className={styles.label}>{item.name}</p>
              </a>
            </Link>
          )
        })}
    </Carousel>
  )
}
